<template>
    <div class="flex  m-auto min-h-screen flex-col">
        <div class="flex w-11/12  2xl:w-3/5 m-auto flex-col lg:flex-row  pt-60 lg:pt-0 relative gap-x-36">
            <div class="flex lg:flex-1 items-center relative md:min-w-[400px]">
                <div class="mx-auto">
                    <div class="flex flex-col justify-center text-left  lg:max-w-full relative ">
                        <p
                            class="mt-6 font-medium text-xl md:text-2xl leading-8 text-justify  lg:max-w-[450px] max-w-[350px] min-w-fit">
                            {{ title }}</p>
                        <p
                            class="mt-6 text-lg md:text-xl leading-8 text-justify  lg:max-w-[450px] max-w-[350px] min-w-fit">
                            {{ content }}</p>
                    </div>
                </div>
            </div>
            <div class=" flex-1 items-center justify-center mt-56 lg:mt-0  hidden lg:flex">
                <div class=" w-[300px] xl:w-[400px] ">
                    <img src="@/assets/logo_s.png">
                </div>
            </div>
        </div>
        <Footer />
    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue'
import Footer from '@/components/Footer.vue';

const title = ref('')
const content = ref('')

onMounted(async () => {
    const response = await fetch(`${process.env.VUE_APP_SERVER}/account${window.location.pathname}`)
    if (response.status == 200) {
        title.value = 'Спасибо за регистрацию!'
        content.value = 'Ваш аккаунт был активирован. Теперь Вы можете приступать к работе с платформой LogoMed'
        return
    }
    title.value = 'Ошибка!'
    content.value = 'Произошла ошибка. Попробуйте пройти регистрацию заново или обратитесь в техническую поддержку'
})



</script>

<style>
.gradient-animation {
    background: linear-gradient(-45deg,
            var(--blue-color) 5%,
            var(--dark-blue-color) 10%,
            var(--blue-color) 20%,
            var(--dark-blue-color) 40%,
            var(--blue-color) 60%,
            var(--dark-blue-color) 80%,
            var(--blue-color) 95%);

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-size: 400% auto;
    animation: mymove 20s ease-in-out infinite alternate;
    /* linear-gradient(80deg, var(--dark-blue-color), var(--blue-color)); */
}


@keyframes mymove {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>