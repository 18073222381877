<template>
    <div class="w-full h-[100px] justify-center align-middle relative bottom-0">
        <div class="mx-auto  max-w-7xl px-2 lg:px-6 align-middle h-[100px]">
            <div class="flex h-[100px] flex-1 items-center align-middle justify-between">
                <div class="flex flex-row gap-x-5 items-center">
                    <!-- <img class="h-8 w-20 md:w-auto" src="@/assets/logo-blue.svg" alt="LogoMed" /> -->
                    <span class="text-xs md:text-base">© 2024, LogoMed</span>
                </div>


                <div class="flex flex-row gap-x-5">
                    <span class=" hidden sm:flex items-center">При поддержке</span>
                    <img src="@/assets/head_logo_fasie.png" alt="fsi" class="w-20 md:w-[100px]">
                </div>

            </div>
            <div class="flex flex-col items-center min-h-14">
                <span class="flex items-center text-center pt-[20px]">Проект создан при поддержке Фондом
                    содействия
                    инновациям в рамках федерального проекта</span>
                <span class="flex items-center text-center pb-[20px] font-bold">«Платформа университетского
                    технологического
                    предпринимательства»</span>
            </div>
        </div>
    </div>
</template>
