<template>
  <Navbar></Navbar>
  <router-view></router-view>
</template>

<script>
import Navbar from './components/Navbar.vue';
export default {
  components: {
    Navbar
  }
}
</script>

<style>
:root {
  --blue-color: #1AADFF;
  --dark-blue-color: #16233E;
  --dark-grey-color: #8892A7;
  --middle-grey-color: #EEEFF1;
  --light-grey-color: #F6F8FF;
}

#app {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;

}
</style>