import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import Registered from '@/views/Registered.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/account/reset-password/:link',
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: '/activate/:link',
    name: 'activate',
    component: Registered
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  // scrollBehavior: function (to, from, savedPosition) {
  //   if (to.hash) {
  //     return { el: to.hash, behavior: 'smooth' }
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // },
  routes
})

export default router
