<template>
    <Disclosure as="nav" class="fixed w-full h-[100px] z-10 top-0 bg-white bg-opacity-90" v-slot="{ open }">
        <div class="mx-auto max-w-7xl px-2 lg:px-6">
            <div class="relative flex items-center justify-between h-[100px]">
                <div class="absolute inset-y-0 left-0 flex items-center lg:hidden">
                    <!-- Mobile menu button-->
                    <DisclosureButton
                        class="relative inline-flex items-center justify-center rounded-md p-2 text-dark-blue focus:outline-none  focus:ring-inset">
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-8 w-8" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-8 w-8" aria-hidden="true" />
                    </DisclosureButton>
                </div>
                <div class="flex flex-1 items-center justify-center lg:items-stretch lg:justify-between">
                    <div class="flex flex-shrink-0 items-center">
                        <a href="#top"><img class="h-8 w-auto" src="@/assets/logo-blue.svg" @click="goHome()"
                                alt="LogoMed" /></a>

                    </div>
                    <div class="hidden lg:ml-6 lg:block">
                        <div class="flex space-x-4">
                            <a v-for="item in navigation" :key="item.name" :href="item.href"
                                class=' hover:text-blue block rounded-md px-3 py-2 text-sm font-medium flex-nowrap text-nowrap'
                                :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <DisclosurePanel class="lg:hidden">
            <div class="space-y-1 px-2 pb-3 pt-2 bg-white bg-opacity-90">
                <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href"
                    class="'text-dark-blue  hover:text-blue block rounded-md px-3 py-2 text-xl font-medium "
                    :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter();
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'

async function goHome() {
    router.push('/')
}

const navigation = [
    { name: 'LogoMed', href: window.location.origin + '/#section1', current: false },
    { name: 'О приложении', href: window.location.origin + '/#section2', current: false },
    { name: 'Стол логопеда', href: window.location.origin + '/#section3', current: false },
    { name: 'Доступ к платформе', href: window.location.origin + '/#section4', current: false },
    // { name: 'Скачать', href: '#section6', current: false },
]
</script>