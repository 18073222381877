<template>
    <div class="flex  m-auto min-h-screen flex-col">
        <div class="flex w-11/12  2xl:w-3/5 m-auto flex-col lg:flex-row  pt-60 lg:pt-0 relative gap-x-36">
            <div class="flex lg:flex-1 items-center relative md:min-w-[400px]">
                <div class="mx-auto">
                    <div class="flex flex-col justify-center text-left  lg:max-w-full relative ">
                        <p
                            class="mt-6 font-medium text-xl md:text-2xl leading-8 text-justify  lg:max-w-[450px] max-w-[350px] min-w-fit">
                            Восстановление пароля к аккаунту</p>
                        <p
                            class="mt-6 text-lg md:text-xl leading-8 text-justify  lg:max-w-[450px] max-w-[350px] min-w-fit">
                            Дважды введите новый пароль и нажмите "сохранить пароль"</p>

                        <form @submit.prevent="restorePassword()"
                            class="mt-10 flex flex-col  gap-y-4 items-center justify-center lg:justify-start gap-x-6">
                            <input type="password"
                                class=" w-full px-3 py-3 rounded-3xl text-dark-blue bg-light-grey focus:outline-none min-w-fit peer"
                                placeholder="Введите пароль" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$"
                                required id="inputPwd" v-model="pwd">
                            <input type="password"
                                class=" w-full px-3 py-3 rounded-3xl text-dark-blue bg-light-grey focus:outline-none min-w-fit"
                                placeholder="Повторите пароль"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$" required id="inputPwdRetry"
                                v-model="pwdRetry">


                            <button
                                class=" px-3 py-3 rounded-3xl text-white bg-blue focus:outline-none min-w-max text-sm"
                                type="submit">Сохранить пароль</button>
                            <span
                                class="hidden text-xs text-blue peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                                - Не менее одной строчной буквы
                            </span>
                            <span
                                class="hidden text-xs text-blue peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                                - Не менее одной прописной буквы
                            </span>
                            <span
                                class="hidden text-xs text-blue peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                                - Не менее одной цифры
                            </span>
                            <span
                                class="hidden text-xs text-blue peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                                - Не менее одного специального символа
                            </span>
                            <span
                                class="hidden text-xs text-blue peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                                - Длина более 8 символов
                            </span>
                        </form>
                    </div>
                </div>
            </div>
            <div class=" flex-1 items-center justify-center mt-56 lg:mt-0  hidden lg:flex">
                <div class=" w-[300px] xl:w-[400px] ">
                    <img src="@/assets/logo_s.png">
                </div>
            </div>
        </div>
        <Footer class="" />
    </div>


</template>

<script setup>
import Footer from '@/components/Footer.vue';
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter();
const pwd = ref('')
const pwdRetry = ref('')
async function restorePassword() {
    if (pwd.value != pwdRetry.value) {
        return window.alert("Пароли не совпадают")
    }
    const magic = router.currentRoute.value.params.link
    try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/account/reset-password/set/${magic}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({ password: pwd.value })
        })
        if (response.status == 201) {

            window.alert("Пароль успешно изменен");
            return router.push('/');
        }
        const result = await response.json()
        pwd.value = ''
        pwdRetry.value = ''
        window.alert(result.message)
        return
    } catch (error) {
        window.alert(error.message)
        return
    }


}



const mobileMenuOpen = ref(false)
</script>

<style>
.gradient-animation {
    background: linear-gradient(-45deg,
            var(--blue-color) 5%,
            var(--dark-blue-color) 10%,
            var(--blue-color) 20%,
            var(--dark-blue-color) 40%,
            var(--blue-color) 60%,
            var(--dark-blue-color) 80%,
            var(--blue-color) 95%);

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-size: 400% auto;
    animation: mymove 20s ease-in-out infinite alternate;
    /* linear-gradient(80deg, var(--dark-blue-color), var(--blue-color)); */
}


@keyframes mymove {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>