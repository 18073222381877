<template>
    <div id="carousel" class="carousel _small">
        <div class="-inner">
            <div class="-item">
                <img src="@/assets/app1.png" alt="Image 1">
                <div class="-text">
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Мобильная версия для лиц с нарушениями речи</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Цветовая палитра приложения не отвлекает от самого
                            главного</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Возможность работы при отсутствии интернет соединения</span>
                    </div>
                </div>
            </div>
            <div class="-item">
                <img src="@/assets/app100.png" alt="Image 2">
                <div class="-text">
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Интуитивно-понятные изображения в упражнениях</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Соблюдение продолжительности выполнения каждого упражнения в
                            его
                            озвучке</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Описание упражнения текстом и аудио-озвучкой</span>
                    </div>
                </div>
            </div>
            <div class="-item">
                <img src="@/assets/app12.png" alt="Image 3">
                <div class="-text">
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Назначение логопедом индивидульных упражнений</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Календарь назначенных упражнений</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Соблюдение периодичности выполнения упражнений</span>
                    </div>
                </div>
            </div>
            <div class="-item">
                <img src="@/assets/app4.png" alt="Image 4">
                <div class="-text">
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Все самое важное на главном экране</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Статистика выполнения назначенных упражнений</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Обратная связь по назначениям от логопеда</span>
                    </div>
                </div>
            </div>
            <div class="-item">
                <img src="@/assets/app5.png" alt="Image 5">
                <div class="-text">
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Шесть разных механик упражнений</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Элементы упражнений разработаны с учетом индивидуальных
                            особенностей лиц с афазией и дизартрией</span></div>

                </div>
            </div>
            <div class="-item">
                <img src="@/assets/app6.png" alt="Image 6">
                <div class="-text">
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Прослушивайте правильную диктовку упражнения</span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Записывайте свое выполнение упражнения </span></div>
                    <div class="text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                            class="arrow-insie-text"> <span>Все ваши аудиозаписи и личные данные хранятся на защищенных
                            серверах приложения</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="-arrows">
            <div class="-arrow _left"></div>
            <div class="-arrow _right"></div>
            <div class="-dots"></div>
        </div>
    </div>
</template>

<script>
import Siema from 'siema';
export default {
    name: 'CarouselMobApp',
    data() {
        return {
            isLoaded: false
        }
    },
    methods: {
        loadPage() {
            var _carousel = document.querySelector('#carousel');
            var _inner = _carousel.getElementsByClassName('-inner')[0];
            (function () {
                var _carousel = document.querySelector('#carousel');
                var siema = new Siema({
                    selector: _inner,
                    duration: 750,
                    easing: 'ease-out',
                    perPage: { //Адаптивность
                        360: 1,
                        576: 1,
                        768: 1,
                        992: 1,
                        1200: 1,
                        1680: 2,


                    },
                    startIndex: 0,
                    draggable: true,
                    multipleDrag: true,
                    threshold: 20,
                    loop: true,
                    rtl: false,
                    onInit: function () {
                        // this.interval = setInterval(function () { //Автоперелистывание
                        //     siema.next();
                        // }, 7000);
                    },
                    onChange: function () {
                        clearInterval(siema.interval);
                        // this.interval = setInterval(function () {
                        //     siema.next();
                        // }, 7000);
                    },
                    interval: null
                });
                //Кнопки влево, вправо
                _carousel.querySelectorAll('.-arrow._left')[0].addEventListener('click', function () {
                    siema.prev();
                });

                _carousel.querySelectorAll('.-arrow._right')[0].addEventListener('click', function () {
                    siema.next();
                });
                //Точки для быстрого перехода
                Siema.prototype.addPagination = function () {
                    var f = function (i) {
                        var _this = this;
                        var btn = document.createElement('button');
                        btn.classList.add('-dot');
                        btn.addEventListener('click', function () {
                            _this.goTo(i);
                            [].forEach.call(this.parentNode.childNodes, function (el) {
                                el.classList.remove('_active');
                            });
                            this.classList.add('_active');
                        });
                        this.selector.parentNode.getElementsByClassName('-dots')[0].appendChild(btn);
                    };
                    for (var i = 0; i < this.innerElements.length; i++) {
                        f.call(this, i);
                    }
                };
                siema.addPagination();

            })();



        }
    },
    mounted() {
        this.loadPage()
        this.isLoaded = true
    }
}
</script>

<style scoped>
.carousel {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    transition: all 0.5s ease-in-out;
}

.carousel .-arrow {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    border-radius: 50%;
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
}

.carousel .-arrow._left {
    left: 25px;
    background: url("@/assets/left-chevron.png") 50% 50% / 20px no-repeat;
}

.carousel .-arrow._right {
    right: 25px;
    background: url("@/assets/right-chevron.png") 50% 50% / 20px no-repeat;
}

.carousel .-dots {
    position: absolute;
    bottom: -25px;
    display: none;
    width: 100%;
    justify-content: center;
}

.carousel .-dot {
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    margin: 0 5px;
    cursor: pointer;
}

.carousel .-dot._active {
    background-color: gray;
}

.carousel .-item {
    display: flex;
    flex-direction: row;
    /* background-color: var(--blue-color); */
    margin: 30px;
    border-radius: 60px;
    max-width: 35vw;
}



.carousel .-item img {
    width: 300px;
    padding: 20px;
    border-radius: 60px;
}

.carousel._small {
    /* background-color: lightgray; */
    padding-bottom: 15px;
}

.carousel._small .-item {
    padding: 0 15px;
    display: flex;
    align-items: center;
    flex-direction: row;

}

.carousel._small .-arrows {
    display: flex;
    justify-content: center;
}

.carousel._small .-arrow {
    margin: 25px 10px;
    position: static;
}

.-text {
    display: flex;
    color: var(--dark-blue-color);
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    row-gap: 30px;
}

.arrow-insie-text {
    width: 30px !important;
    padding: 10px !important;
}

.text-inside-span {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.1rem;

}

.text-inside-span span {
    width: 80%;

}

@media (max-width: 1680px) {
    .carousel .-item {
        max-width: none;
    }
}

@media (max-width: 768px) {
    .carousel._small .-item {
        flex-direction: column;
    }

    .text-inside-span {
        justify-content: center;
    }
}

@keyframes mymove {
    0% {
        color: var(--dark-blue-color);
    }

    50% {
        color: white
    }

    100% {
        color: var(--dark-blue-color);
    }
}
</style>