<template>
    <div id="desktop-carousel" class="desktop-carousel _small">
        <div class="-desktop-inner">
            <div class="-desktop-item">
                <div class="-desktop-title">Составление назначений</div>
                <div class="-desktop-content">
                    <img src="@/assets/desktop4.png" alt="Image 4">
                    <div class="-desktop-text">
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Упражнения удобно разделены по блокам -
                                направленностям</span>
                        </div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Контент каждого упражнения можно просмотреть и
                                прослушать перед его назначением</span></div>

                    </div>
                </div>
            </div>
            <div class="-desktop-item">
                <div class="-desktop-title">Составление назначений</div>
                <div class="-desktop-content">
                    <img src="@/assets/desktop6.png" alt="Image 5">
                    <div class="-desktop-text">
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Выбор периодичности выполнения каждого
                                упражнения в назначении</span>
                        </div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Возможность оставления комментария к
                                назначенному упражнению</span></div>

                    </div>
                </div>
            </div>
            <div class="-desktop-item">
                <div class="-desktop-title">Обратная связь</div>
                <div class="-desktop-content">
                    <img src="@/assets/desktop7.png" alt="Image 6">
                    <div class="-desktop-text">
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Возможность прослушивания и просмотра каждой
                                итерации выполнения назначенного упражнения</span>
                        </div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Возможность оставления обратной связи по
                                выполненному упражнению</span></div>

                    </div>
                </div>
            </div>
            <div class="-desktop-item">
                <div class="-desktop-title">Личный кабинет логопеда</div>
                <div class="-desktop-content">
                    <img src="@/assets/desktop1.png" alt="Image 1">
                    <div class="-desktop-text">
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Просмотр и изменение расписания</span>
                        </div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Прикрепление сертификатов о квалификации</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="-desktop-item">
                <div class="-desktop-title">Медицинская карта</div>
                <div class="-desktop-content">
                    <img src="@/assets/desktop2.png" alt="Image 2">
                    <div class="-desktop-text">
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Просмотр медицинской карты прикрепленного к
                                логопеду пациента</span>
                        </div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Отображение диагноза, описания и результатов
                                выполнения назначений</span></div>
                    </div>
                </div>
            </div>
            <div class="-desktop-item">
                <div class="-desktop-title">Информация о пациенте</div>
                <div class="-desktop-content">
                    <img src="@/assets/desktop3.png" alt="Image 3">
                    <div class="-desktop-text">
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Удобное отображение личных данных
                                пациента</span>
                        </div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Отображение списка анамнезов, возможность
                                добавлять новые</span></div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Просмотр листа назначений</span></div>
                    </div>
                </div>
            </div>


            <div class="-desktop-item">
                <div class="-desktop-title">Администратор клиники</div>
                <div class="-desktop-content">
                    <img src="@/assets/desktop5.png" alt="Image 6">
                    <div class="-desktop-text">
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Возможность просмотра пациентов и логопедов
                                клиники</span>
                        </div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Возможность назначения пациента конкретному
                                логопеду</span></div>
                        <div class="desktop-text-inside-span"><img src="@/assets/right-chevron.png" alt="Image 1"
                                class="desktop-arrow-insie-text"> <span>Возможность предоставления доступа к платформе
                                новому логопеду клиники</span></div>
                    </div>
                </div>
            </div>

        </div>
        <div class="-desktop-arrows">
            <div class="-desktop-arrow _left"></div>
            <div class="-desktop-arrow _right"></div>
            <div class="-desktop-dots"></div>
        </div>
    </div>
</template>
<script>
import Siema from 'siema';
export default {
    name: 'CarouselDesktop',
    data() {
        return {
            isLoaded: false
        }
    },
    methods: {
        loadPage() {
            var _carousel = document.querySelector('#desktop-carousel');
            var _inner = _carousel.getElementsByClassName('-desktop-inner')[0];
            (function () {
                var _carousel = document.querySelector('#desktop-carousel');
                var siema = new Siema({
                    selector: _inner,
                    duration: 750,
                    easing: 'ease-out',
                    perPage: { //Адаптивность
                        360: 1,
                        576: 1,
                        768: 1,
                        992: 1,
                        1200: 1,
                        1680: 1,

                    },
                    startIndex: 0,
                    draggable: true,
                    multipleDrag: true,
                    threshold: 20,
                    loop: true,
                    rtl: false,
                    onInit: function () {
                        // this.interval = setInterval(function () { //Автоперелистывание
                        //     siema.next();
                        // }, 7000);
                    },
                    onChange: function () {
                        clearInterval(siema.interval);
                        // this.interval = setInterval(function () {
                        //     siema.next();
                        // }, 7000);
                    },
                    interval: null
                });
                //Кнопки влево, вправо
                _carousel.querySelectorAll('.-desktop-arrow._left')[0].addEventListener('click', function () {
                    siema.prev();
                });

                _carousel.querySelectorAll('.-desktop-arrow._right')[0].addEventListener('click', function () {
                    siema.next();
                });
                //Точки для быстрого перехода
                Siema.prototype.addPagination = function () {
                    var f = function (i) {
                        var _this = this;
                        var btn = document.createElement('button');
                        btn.classList.add('-desktop-dot');
                        btn.addEventListener('click', function () {
                            _this.goTo(i);
                            [].forEach.call(this.parentNode.childNodes, function (el) {
                                el.classList.remove('_active');
                            });
                            this.classList.add('_active');
                        });
                        this.selector.parentNode.getElementsByClassName('-desktop-dots')[0].appendChild(btn);
                    };
                    for (var i = 0; i < this.innerElements.length; i++) {
                        f.call(this, i);
                    }
                };
                siema.addPagination();

            })();



        }
    },
    mounted() {
        this.loadPage()
        this.isLoaded = true
    }
}
</script>
<style scoped>
.desktop-carousel {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    /* display: flex; */
    margin: auto;
    transition: all 0.5s ease-in-out;
}

.desktop-carousel .-desktop-arrow {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    border-radius: 50%;
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
}

.desktop-carousel .-desktop-arrow._left {
    left: 25px;
    background: url("@/assets/left-chevron.png") 50% 50% / 20px no-repeat;
}

.desktop-carousel .-desktop-arrow._right {
    right: 25px;
    background: url("@/assets/right-chevron.png") 50% 50% / 20px no-repeat;
}

.desktop-carousel .-desktop-dots {
    position: absolute;
    bottom: -25px;
    display: none;
    width: 100%;
    justify-content: center;
}

.desktop-carousel .-desktop-dot {
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    margin: 0 5px;
    cursor: pointer;
}

.desktop-carousel .-desktop-dot._active {
    background-color: gray;
}

.desktop-carousel .-desktop-item {
    display: flex;
    flex-direction: column;
    /* flex-direction: row; */
    /* background-color: var(--blue-color); */
    /* margin: 30px; */
    padding: 30px;
    border-radius: 60px;
}

.desktop-carousel .-desktop-item .-desktop-content {
    display: flex;
    flex-direction: row;
}

.desktop-carousel .-desktop-item .-desktop-title {
    color: var(--dark-blue-color);
    font-size: 3rem;
    margin-bottom: 20px;
    padding-left: 5vw;
    text-align: start;
    font-weight: 400;
}

.desktop-carousel .-desktop-item img {
    max-width: 50vw;
}

.desktop-carousel._small {
    /* background-color: lightgray; */
    padding-bottom: 15px;
}

.desktop-carousel._small .-desktop-item {
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
}

.desktop-carousel._small .-desktop-arrows {
    display: flex;
    justify-content: center;
}

.desktop-carousel._small .-desktop-arrow {
    margin: 25px 10px;
    position: static;
}

.-desktop-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    row-gap: 30px;
}

.desktop-arrow-insie-text {
    width: 30px !important;
    padding: 10px !important;
}

.desktop-text-inside-span {
    display: flex;
    justify-content: left;
    align-items: center;
    color: var(--dark-blue-color);
    font-size: 1.5rem;
}

.desktop-text-inside-span span {
    /* width: 80%; */
    text-align: left;
}

@media (max-width: 1680px) {
    .desktop-carousel .-desktop-item img {
        max-width: 600px;
    }

    .desktop-carousel .-desktop-item .-desktop-title {
        font-size: 2.5rem;
        padding-left: 3vw;
    }

    .desktop-text-inside-span {

        font-size: 1.1rem;
    }
}

@media (max-width: 1280px) {
    .desktop-carousel .-desktop-item img {
        max-width: 480px;
    }
}

@media (max-width: 1024px) {
    .desktop-carousel._small .-desktop-item {
        justify-content: center;
        align-items: center;
    }

    .desktop-carousel .-desktop-item .-desktop-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
    }

    .desktop-carousel .-desktop-item .-desktop-title {

        padding-left: 0;
    }

    /* .desktop-text-inside-span {
        justify-content: center;
    } */
}

@media (max-width: 750px) {
    .desktop-carousel .-desktop-item .-desktop-title {
        font-size: 1.5rem;
    }

    .desktop-carousel._small .-desktop-item {

        max-width: 80%;

    }
}

@media (max-width: 500px) {
    .desktop-carousel .-desktop-item .-desktop-title {
        font-size: 1.2rem;
    }

    .desktop-carousel .-desktop-item img {
        max-width: 90vw;
    }
}
</style>