<template>
    <div class="flex w-4/5  xl:w-3/5 m-auto min-h-svh flex-col lg:flex-row  pt-60 lg:pt-0 relative">
        <div class="flex lg:flex-2 items-center relative">
            <div class="mx-auto">
                <div class="flex flex-col justify-center text-left max-w-min lg:max-w-full">
                    <div class="flex">
                        <h1 class=" font-bold tracking-tight 2xl:text-8xl w-3/4 text-6xl text-dark-blue">Logo<span
                                class="gradient-animation">Med</span> <span class="gradient-animation">Platform</span>
                        </h1>
                    </div>
                    <p class="mt-6 text-xl leading-8 text-justify  2xl:max-w-[450px] max-w-[350px]">
                        Индивидуальный тренажер
                        коррекции и
                        восстановления
                        речи
                        у лиц разного возраста при афазии и дизартрии</p>
                    <div class="mt-10 flex items-center justify-center lg:justify-start gap-x-6">
                        <h6 class=" px-3.5 py-2.5 text-sm font-semibold min-w-[178px] hidden 2xl:flex">
                            Скачать приложение</h6>
                        <a href="https://www.rustore.ru/catalog/app/com.dmisim.logomed_app"
                            class="text-sm font-semibold leading-6 text-gray-900">
                            <div class=" ">
                                <img src="@/assets/rustore.svg" alt="RuStore" title="RuStore">
                            </div>
                        </a>
                        <a href="https://t.me/logomed_platform" class="text-sm font-semibold leading-6 text-gray-900">
                            <div class=" ">
                                <img src="@/assets/tg-icon.png" class="max-h-14" alt="telegram" title="telegram">
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class=" flex-1 items-center justify-center mt-56 lg:mt-0  hidden lg:flex">
            <div class=" w-[300px] lg:w-[270px] 2xl:w-[320px]">
                <img src="@/assets/app1.png">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
]

const mobileMenuOpen = ref(false)
</script>

<style>
.gradient-animation {
    background: linear-gradient(-45deg,
            var(--blue-color) 5%,
            var(--dark-blue-color) 10%,
            var(--blue-color) 20%,
            var(--dark-blue-color) 40%,
            var(--blue-color) 60%,
            var(--dark-blue-color) 80%,
            var(--blue-color) 95%);

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-size: 400% auto;
    animation: mymove 20s ease-in-out infinite alternate;
    /* linear-gradient(80deg, var(--dark-blue-color), var(--blue-color)); */
}


@keyframes mymove {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>