<template>
  <div class="home">
    <div id="section1" ref="section1">
      <SectionOne />
    </div>
    <div class="flex mx-auto lg:w-3/4 min-h-svh align-middle justify-center mt-56 lg:mt-0" id="section2" ref="section2">
      <CarouselMobApp />
    </div>
    <div class="flex mx-auto w-full lg:w-4/5 xl:w-3/4 min-h-svh align-middle justify-center" id="section3"
      ref="section3">
      <CarouselDesktop />
    </div>
    <div id="section4" ref="section4">
      <Contacts />
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import CarouselMobApp from '@/components/CarouselMobApp.vue'
import CarouselDesktop from '@/components/CarouselDesktop.vue';
import SectionOne from '@/components/SectionOne.vue';
import Footer from '@/components/Footer.vue';
import Contacts from '@/components/Contacts.vue';

export default {
  name: 'HomeView',
  mounted() {
    var options = {
      rootMargin: '0px',
      threshold: 0.9
    }
    var callback = (entries, observer) => {
      if (entries[0].isIntersecting) {
        this.$router.replace({ hash: `#${entries[0].target.id}` })
      }
    };
    setTimeout(() => {
      var observer = new IntersectionObserver(callback, options);
      if (this.$refs?.section1 != null) {
        observer.observe(this.$refs?.section1)
      }
      if (this.$refs?.section2 != null) {
        observer.observe(this.$refs?.section2)
      }
      if (this.$refs?.section3 != null) {
        observer.observe(this.$refs?.section3)
      }
    }, 1000)

  },
  components: {
    CarouselMobApp,
    CarouselDesktop,
    SectionOne,
    Footer,
    Contacts
  }
}
</script>
