<template>
    <div class="flex w-11/12  2xl:w-3/5 m-auto min-h-screen flex-col lg:flex-row  pt-60 lg:pt-0 relative gap-x-36">
        <div class="flex lg:flex-1 items-center relative md:min-w-[400px]">
            <div class="mx-auto">
                <div class="flex flex-col justify-center text-left  lg:max-w-full relative ">
                    <p
                        class="mt-6 font-medium text-xl md:text-2xl leading-8 text-justify  lg:max-w-[450px] max-w-[350px] min-w-fit">
                        Получить доступ к платформе</p>
                    <p class="mt-6 text-lg md:text-xl leading-8 text-justify  lg:max-w-[450px] max-w-[350px] min-w-fit">
                        Оставьте заявку для добавления вашей клиники на платформу LogoMed</p>
                    <form @submit.prevent="sendRequest()"
                        class="mt-10 flex flex-col  gap-y-4 items-center justify-center lg:justify-start gap-x-6">
                        <input type="email"
                            class=" w-full px-3 py-3 rounded-3xl text-dark-blue bg-light-grey focus:outline-none min-w-fit"
                            placeholder="Ваша почта" @input="sanitizeInputEmail()" id="inputEmail" v-model="email"
                            required>
                        <textarea id="textArea" rows="5"
                            class="w-full px-3 py-3 rounded-3xl text-dark-blue bg-light-grey focus:outline-none min-w-fit resize-none"
                            placeholder="Дополнительная информация" v-model="content" required></textarea>
                        <button class=" px-3 py-3 rounded-3xl text-white bg-blue focus:outline-none min-w-max text-sm"
                            type="submit">Оставить
                            заявку</button>
                    </form>
                </div>
            </div>
        </div>
        <div class=" flex-1 items-center justify-center mt-56 lg:mt-0  hidden lg:flex">
            <div class=" w-[300px] xl:w-[400px] ">
                <img src="@/assets/logo_s.png">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const email = ref('')
const content = ref('')

async function sendRequest() {
    try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/platform/request-access`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({ email: email.value, content: content.value })
        })
        if (response.status == 200) {
            window.alert("Ваша заявка отправлена!");
            return
        }
        const result = await response.json()
        window.alert(result.message)
        return
    } catch (error) {
        window.alert(error.message)
        return
    }
}
function sanitizeInputEmail() {
    let inputElement = document.getElementById('inputEmail');
    let sanitizedValue = inputElement.value.replace(/</g, "").replace(/>/g, "").replace("=", "").replace(";", "");
    inputElement.value = sanitizedValue;
}



const mobileMenuOpen = ref(false)
</script>

<style>
.gradient-animation {
    background: linear-gradient(-45deg,
            var(--blue-color) 5%,
            var(--dark-blue-color) 10%,
            var(--blue-color) 20%,
            var(--dark-blue-color) 40%,
            var(--blue-color) 60%,
            var(--dark-blue-color) 80%,
            var(--blue-color) 95%);

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-size: 400% auto;
    animation: mymove 20s ease-in-out infinite alternate;
    /* linear-gradient(80deg, var(--dark-blue-color), var(--blue-color)); */
}


@keyframes mymove {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>